<template>
  <div class="fve-search">
    <FveText
      placeholder="Найти…"
      v-model="searchValue"
      @keypress-enter="search"
    />
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.5 15.5" v-show="shownClear" v-bind:svg-inline="''" v-bind:class="'fve-search__icon fve-search__icon--clear'" @click="clear" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#555"><path d="M.76 15.49a.75.75 0 01-.53-1.28l14-14a.75.75 0 011.06 1.06l-14 14a.75.75 0 01-.53.22z"/><path d="M14.75 15.5a.75.75 0 01-.53-.22l-14-14A.75.75 0 011.28.22l14 14a.75.75 0 01-.53 1.28z"/></g></svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="13.23" height="13.51" viewBox="0 0 13.23 13.51" v-bind:svg-inline="''" v-bind:class="'fve-search__icon fve-search__icon--search'" @click="search" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.37 12.74a6.37 6.37 0 116.37-6.37 6.37 6.37 0 01-6.37 6.37zm0-11.24a4.87 4.87 0 104.87 4.87A4.87 4.87 0 006.37 1.5z"/><path d="M12.48 13.51a.75.75 0 01-.53-.22l-2.2-2.2A.75.75 0 0110.8 10l2.2 2.2a.75.75 0 01-.53 1.28z"/></svg>
  </div>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";

export default {
  name: 'FveSearchText',
  components: {
    FveText,
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      searchValue: this.value
    };
  },
  computed: {
    shownClear() {
      return !!this.searchValue;
    }
  },
  methods: {
    clear() {
      this.searchValue = '';
    },
    search() {
      this.$emit('search', this.searchValue);
    }
  },
};
</script>


<style lang="scss" scoped>
.fve-search {
  position: relative;
  ::v-deep input {
    height: 30px;
    padding: 4px 54px 4px 16px;
    margin-bottom: 0;
  }
  .fve-search__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    path {
      fill: var(--text-sub);
      transition: all 0.2s ease-in-out;
    }
    &--clear {
      right: 34px;
      height: 12px;
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
    &--search {
      right: 14px;
      height: 14px;
      &:hover {
        path {
          fill: var(--color-prime);
        }
      }
    }
  }
}
</style>
